.info .card {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 5px
}

.info .card-body img {
  width: 150px;
  margin-top: 38px
}

.p-100 {
  height: 250px;
  overflow-y: scroll;
}
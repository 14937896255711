.w-50 button, .MuiButtonBase-root svg, .MuiTableCell-root button.btn-primary{
  background: #179349;
  border-color: #fff;
  border-radius: 2px;
  color: #fff;
}

.w-50 button:hover, .MuiButtonBase-root svg, .MuiInput-underline svg, .MuiTableCell-root button.btn-primary:hover{
  background: #fff;
  color: #179349;
}
.w-50 button:hover, .MuiButtonBase-root svg, .MuiTableCell-root button.btn-primary {
  border: 1px solid #179349;
}

.MuiInputBase-colorPrimary.Mui-focsed {
  border-bottom: #179349 !important;
}
.MuiTableCell-root button.btn-primary {
  margin-right: 10px !important;
}

.MuiButtonBase-root svg {
  border: none !important;
}

.MuiButtonBase-root svg:hover {
  background: none;
}

.w-50:first-child{
  margin-left: 2px;
}

.form-select:focus, .Mui-focused {
  box-shadow: none;
  border-color: #179349;
}

.bold {
  font-weight: 550;
}

.bold::after {
  content: ":";
  text-align: right;
}
.text-justify{
  text-align: justify;
}

.d-flex.align-items-center.w-50.row, .MuiTableCell-root button.btn-primary {
  height: 30px;
}

td .row, .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-hidden.css-bz4dnt-MuiCollapse-root, .MuiBox-root.css-1llu0od {
  height: 35px !important;
}
#form_login {
	height: 100vh;
}

#left_login {
	background: url(https://lh3.googleusercontent.com/d/1SGNdNbZRIdQFdD32GSPJC1IlbZSv_PDN) no-repeat;
	height: 100%;
	max-width: 100%;
	background-position-y: center;
}

#rigth_login {
	background: url(https://lh3.googleusercontent.com/d/1jZ69rTDOWKT5nsufTMdGv0gZz8btdvwF) repeat;
	padding: 0px 60px 0px 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#rigth_login .gap-2 button {
	background: #179349;
	border-radius: 2px;
	color: #fff;
	padding: 10px 0px !important;
	border: none;
  outline: none !important;
}

#rigth_login .gap-2 button:hover{
  background: #fff;
	color: #179349;
  border: 1px solid #179349;
}

.login__form {
	--background: linear-gradient(90deg, #2e4e827b, #1e4d9d);	
	
}

.login__container {
	display: flex;
	align-items: center;
	justify-content: center;
  min-height: 100vh;
}

.screen {		
	background: linear-gradient(90deg, #5cc73e41, #1793486b);		
	position: relative;	
	min-height: 100vh;
	width: 360px;	
	box-shadow: 0px 0px 24px #179348c6;
	overflow: hidden;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #179349;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #0f572cfc, #1dc761c6);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #179348c6;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}
.screen__content .logo {
  width: 100%;
  padding-top: 50px;
  padding-left: 60px;
}

.screen__content .logo img {
  height: 100px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 10px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #179349;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #179349;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: bold;
	display: flex;
	align-items: center;
	width: 100%;
	color: #179349;
	box-shadow: 0px 2px 2px #209d52f5;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #179349;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #179349;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #376cc2;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}

.social-login__icon:hover a svg{
	color: #fff;
}

.card p.nodata ,tr.nodata {
	text-align: center;
}

p.error {
	color : #D21010;
	margin-bottom: -20px;
	padding-left: 10px;
}

span.red-text, p.red-text {
	color : #D21010;
	font-size: 14px;
	font-weight: 700;
	flex-wrap: wrap;
}

p.red-text{
	margin-bottom: -20px;
	margin-left: -20px;
}

p.red-text:empty{
	margin-bottom: 0px;
	margin-left: 0px;
}

span.center {
	margin-left: 160px;
	margin-top: 10px
}

@media (max-width: 991px) {
	#rigth_login{
		padding: 0px 30px !important;
	}
}
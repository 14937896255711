.section-content .all-content .contenu {
  height: calc(100vh - 160px);
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px;
  overflow-x: hidden;
}

.noaction{
  --overflow-y: hidden !important;
}

.est {
  height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 64px;
  height: 64px;
  animation: rotate 1.5s ease-in infinite alternate;
}
.loader::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FF6C2C;
  background: currentColor;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
}
.loader::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 10%;
  background: #179349;
  width: 8px;
  height: 64px;
  animation: rotate 1.2s linear infinite alternate-reverse;
}

@keyframes rotate {
  100% { transform: rotate(360deg)}
}
      
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.container-fluid {
  overflow-x: hidden;
}

.content {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 160px);
  overflow-x: hidden;
  margin-top: 100px;
}

.section-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.section-content .all-content {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.pt {
  padding-top: 100px;
}

.Mui-selected .css-1gh6k7f-MuiBottomNavigationAction-label {
  color:  #179349 !important;
}

.MuiBottomNavigationAction-iconOnly {
  padding: 3px !important;
}

.css-1gh6k7f-MuiBottomNavigationAction-label {
  opacity: 1 !important;
  font-weight: bold !important;
}
.section-content .row .submenu {
  margin-left: 70px;
  border-right: 1px solid #179349;
  height: 100%;
  padding-left: 20px;
  background: #17934872;
  
}

.submenu ul {
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 5px;
  padding-top: 40px;
  height: 100%;
}

.submenu ul li {
  position: relative;
  list-style: none;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.submenu ul li a {
  position: relative;
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #fff;
}

.submenu ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  text-align: center;
  line-height: 70px;
  color: #179449;
}

.submenu ul li a .icon svg {
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
}

.submenu ul li a .title {
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;
}

.submenu ul li.active {
  background: #6bff2c76;
}

.submenu ul li.active a::before {
  content: "";
  position: absolute;
  top: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  --box-shadow: 15px 15px 0 #b9d5ee58;
}

.submenu ul li.active a::after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  --box-shadow: 15px -15px 0 #b9d5ee58;
}

.submenu ul li a:hover {
  border-left: 5px solid #FF6C2C;
}

.submenu ul li.active a:hover,
.submenu ul li.logout a:hover {
  border-left: 0px;
}

.submenu ul li.logout {
  position: absolute;
  bottom: 0px;
}
.header {
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  height: 100px;
  border-bottom: 3px solid #179349;
  padding-top: 5px;
  background: #fff;
  z-index: 10;
}

.header .logo,  .header .search{
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .logo img {
  width: 100%;
  height: 50px;
  margin-left: 30px;
}

.header .search {
  width: 100%;
  margin-top: -4px;
}

.header .search .search-box {
  width: 200px;
  background: #fff;
  text-align: center;
  font-size: 1.5rem;
  color: #FF6C2C;
  border-radius: 10px;
  cursor: pointer;
}

.header .search .search-box .icon {
  width: 60px;
  height: 60px;
  text-align: center;
  color: #179349;
}

.header .search .search-box .icon ion-icon {
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
  padding-top: 7px;
}

.modal {
  display: none; 
  position: fixed; 
  z-index: 1;
  padding-top: 80px; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #17934872;
  margin: auto;
  padding: 2px;
  border: 2px solid #179349;
  width: 50%;
}

.modal-content .card {
  background: #fff;
  padding: 10px;
  color: #179349;
}

.modal-content .card input {
  width: 77%;
  padding: 5px;
  border: none;
  outline: none;
}

.modal-content .card input:focus{
  border: none;
}

.close {
  color: #ff000092;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding-right: 5px;
}

.close:hover,
.close:focus {
  color: #FF0200;
  text-decoration: none;
  cursor: pointer;
}

.header .search .search-box .title {
  position: relative;
  padding-left: 10px;
  height: 60px;
  white-space: nowrap;
}

.header .user {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: end;
  width: 70px;
}

.header .user label {
  cursor: pointer;
}

.header .user label img {
  height: 50px;
  border-radius: 50%;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}
.button span {
  float: right;
}

.checked {
  display: none;
}

.header .user .icon {
  display: none;
  position: relative;
  color: #179349;
  font-size: 1.3rem;
  margin-top: -30px;
  margin-right: 5px;
  z-index: -1;
}

.header .user ul {
  display: none;
  list-style: none;
  position: absolute;
  top: 85px;
  width: 170px;
  background: #179349;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 0px;
  box-shadow: 5px 5px 5px #1cd30b84;
}

[id^="btn"]:checked + ul {
  display: flex;
}

.header .user ul:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: -5px;
  right: 10px;
  background: #179349;
  transform: rotate(45deg);
}

.header .user ul li a {
  text-decoration: none;
}

.header .user ul li a .icon {
  height: 50px;
  width: 50px;
}

.header .user ul li a .icon ion-icon,
.header .user ul li a .title {
  color: #fff;
  font-size: 1.3rem;
}

.header .user ul li a .title {
  padding-left: 10px;
}

@media (min-width: 767px){
  .header .user{
    padding-right: 30px;
  }
}
.title svg {
  font-size: 2.5em;
  margin: 20px 0px;
}

.clock svg {
  font-size: 1em !important;
  color: #ccc;
  margin-right: 10px;
}

.question {
  font-weight: 600;
  color: #179349;
}

.quiz {
  text-align: justify;
}

.testscrool {
  height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px;
  border-radius: 5px;
}

.test {
  overflow: hidden;
  padding-bottom: 10px;
}
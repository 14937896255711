iframe {
  width: 100%;
  height: 450px;
}

.carousel-black1 {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}
.carousel-item img{
  object-fit: cover;
  height: 100vh;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.carousel-caption {
  text-align:left !important;
  top: 50% !important
}

.carousel-caption h1, .carousel-caption h3 {
  margin: 0;
  color: #ffffff !important;
  text-transform: uppercase;
}

.carousel-caption h1 {
  font-size: 4rem;
  font-weight: 600;
}

.carousel-caption h3 {
  font-size: 2rem;
  font-weight: 400;
}

.parallax_window_in, .parallax_window {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#about_banner {
  background-image: url(https://lh3.googleusercontent.com/d/1gc3IiPPdZA6wW6Rb39va0dGM-9nOIlSt);
  height: 65vh;
}

#faq_banner {
  background-image: url(https://lh3.googleusercontent.com/d/1wsXXR7kRJp-HoYarkbs0oM5w3s2ltdXB);
  height: 75vh;
  /* Image Alkassoum 1niYqtucKaeyon3-owV9Ye5IBagvpMEJJ */
   /* Image Lateefah 1wsXXR7kRJp-HoYarkbs0oM5w3s2ltdXB */
  /* Image hanata 18pjm7OMnwrVKvwG5FF4hZ7nDZ7Kq1XzE */ 
  /* image haoua 1MhFjz3nOLkyJ_5Wzp_qATBlz-esNZxbe */
}

#home_banner {
  background-image: url(https://lh3.googleusercontent.com/d/1Aum5kUMgYXaM6kVrEt_rJHfFh11lxjn5);
  height: 100vh;
  /* https://drive.google.com/file/d/1Aum5kUMgYXaM6kVrEt_rJHfFh11lxjn5/view?usp=drive_link*/
}

#sub_content_in1 h1 {
  overflow: hidden; 
  border-right: 0.15em solid #198753;
  white-space: nowrap; 
  margin: 0 auto; 
  animation: typing 9s steps(40, end), blink-caret 0.75s step-end;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #198753;
  }
}

@media (max-width : 425px) {
  .carousel-caption h1 {
    font-size: 3rem;
  }
  .carousel-caption h3 {
    font-size: 1.5rem;
  }

  #home_banner {
    background-position: center;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  .parallax_window_in, .parallax_window {
    background-attachment: scroll;
  }
}
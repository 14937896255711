.container {
  max-width: 1140px !important
}

header {
  position: relative;
  --padding: 0px 30px;
  --margin-bottom: 73px;
}
header .mb {
  margin-bottom: 98px;
}


.btn-nav {
  margin-top: 20px;
}

.btn-nav a{
  background: #179349;
  border-radius: 2px;
  color: #fff;
}

.btn-nav a:hover{
  background: #fff;
  color: #179349;
  border: 1px solid #179349;
}

nav.hover:hover{
  background: #fff;
  border-bottom: 2px solid #179349;
  ;
}

.navbar-brand {
  animation: bounce-in 1.5s ease;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

header nav {
  padding: 20px 0px !important;
}

header nav button {
  transform: rotate(0deg);
}

header nav button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

header nav button.active span {
  transform: rotate(90deg);
}

header nav ul {
  animation: bounce-in-right 2s;
  padding-left: 0 !important;
}

header nav ul li a:hover {
  color: #555;
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

header .cd-nav-trigger {
  top: 0;
}
header #social {
  top: 5px;
}
@media (max-width: 991px) {
  header #social {
    right: 70px;
  }
}
header #social ul {
  margin: 0;
  padding: 0;
}
header #social ul li {
  float: left;
  margin: 0 5px 10px 0;
  list-style: none;
}
header #social ul li a {
  color: #555;
  text-align: center;
  line-height: 35px;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header #social ul li a:hover {
  color: #179349;
}

.cd-nav-trigger {
  position: absolute;
  display: inline-block;
}

.cd-nav-trigger {
  top: 15px;
  right: 20px;
  height: 44px;
  width: 44px;
  z-index: 5;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .cd-nav-trigger {
    top: 10px;
    right: 15px;
  }
}

.cd-nav-trigger .cd-icon {
  /* icon created in CSS */
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  width: 18px;
  height: 2px;
  background-color: #222;
  z-index: 10;
}

.cd-nav-trigger .cd-icon::before,
.cd-nav-trigger .cd-icon:after {
  /* upper and lower lines of the menu icon */
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #222;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* apply transition to transform property */
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-nav-trigger .cd-icon::before {
  -webkit-transform: translateY(-6px) rotate(0deg);
  -moz-transform: translateY(-6px) rotate(0deg);
  -ms-transform: translateY(-6px) rotate(0deg);
  -o-transform: translateY(-6px) rotate(0deg);
  transform: translateY(-6px) rotate(0deg);
}

.cd-nav-trigger .cd-icon::after {
  -webkit-transform: translateY(6px) rotate(0deg);
  -moz-transform: translateY(6px) rotate(0deg);
  -ms-transform: translateY(6px) rotate(0deg);
  -o-transform: translateY(6px) rotate(0deg);
  transform: translateY(6px) rotate(0deg);
}

.cd-nav-trigger::before,
.cd-nav-trigger::after {
  /* 2 rounded colored backgrounds for the menu icon */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
}

.cd-nav-trigger::before {
  background-color: #e9e9e9;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
@media (max-width: 991px) {
  .cd-nav-trigger::before {
    background-color: #fff;
  }
}

@media (max-width: 991px) {
  header .cd-nav-trigger::before {
    background-color: #ededed;
  }
}

.cd-nav-trigger::after {
  background-color: #ededed;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.cd-nav-trigger.close-nav::before {
  /* user clicks on the .cd-nav-trigger element - 1st rounded background disappears */
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.cd-nav-trigger.close-nav::after {
  /* user clicks on the .cd-nav-trigger element - 2nd rounded background appears */
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cd-nav-trigger.close-nav .cd-icon {
  /* user clicks on the .cd-nav-trigger element - transform the icon */
  background-color: transparent;
}

.cd-nav-trigger.close-nav .cd-icon::before,
.cd-nav-trigger.close-nav .cd-icon::after {
  background-color: #222;
}

.cd-nav-trigger.close-nav .cd-icon::before {
  -webkit-transform: translateY(0) rotate(45deg);
  -moz-transform: translateY(0) rotate(45deg);
  -ms-transform: translateY(0) rotate(45deg);
  -o-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.cd-nav-trigger.close-nav .cd-icon::after {
  -webkit-transform: translateY(0) rotate(-45deg);
  -moz-transform: translateY(0) rotate(-45deg);
  -ms-transform: translateY(0) rotate(-45deg);
  -o-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

.cd-primary-nav {
  /* by default it's hidden */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 80px 5%;
  z-index: 3;
  background-color: #fff;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.3s;
  -moz-transition: visibility 0s, opacity 0.3s;
  transition: visibility 0s, opacity 0.3s;
}

ul.cd-primary-nav {
  list-style: none;
  padding: 80px 0 0 0;
  margin: 0;
}

.cd-primary-nav li {
  margin: 1.2em 0;
  text-align: center;
  text-transform: capitalize;
}

.cd-primary-nav a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
  color: #777;
  font-size: 18px;
}

.no-touch .cd-primary-nav a:hover {
  color: #179349;
}

.cd-primary-nav.fade-in {
  /* navigation visible at the end of the circle animation */
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .cd-primary-nav li {
    margin: 2em 0;
  }

  .cd-primary-nav a {
    font-size: 21px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-primary-nav li {
    margin: 2.6em 0;
  }

  .cd-primary-nav a {
    font-size: 24px;
  }
}
.cd-overlay-nav,
.cd-overlay-content {
  /* containers of the 2 main rounded backgrounds - these containers are used to position the rounded bgs behind the menu icon */
  position: fixed;
  top: 18px;
  right: 5%;
  height: 4px;
  width: 4px;
  -webkit-transform: translateX(-20px) translateY(20px);
  -moz-transform: translateX(-20px) translateY(20px);
  -ms-transform: translateX(-20px) translateY(20px);
  -o-transform: translateX(-20px) translateY(20px);
  transform: translateX(-20px) translateY(20px);
}

.cd-overlay-nav span,
.cd-overlay-content span {
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.cd-overlay-nav.is-hidden,
.cd-overlay-content.is-hidden {
  /* background fades out at the end of the animation */
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.cd-overlay-nav {
  /* main rounded colored bg 1 */
  z-index: 2;
}

.cd-overlay-nav span {
  background-color: #179349;
}

.cd-overlay-content {
  /* main rounded colored bg 2 */
  z-index: 4;
}

.cd-overlay-content span {
  background-color: #179349;
}

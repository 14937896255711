.girl #left_form {
  background-color: #93178d;
  color: #fff;
  text-align: center;
  padding: 0px 10px ;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}


.girl h3.main_question svg {
  position: absolute;
  left: -30px;
  font-weight: bold;
  color: #93178d;
  font-size: 21px;
  font-size: 1.3125rem;
  top: 2px;
}

.girl .form-control:focus {
  box-shadow: none;
  border-color: #93178d !important;
}

/* Wizard Buttons*/
.girl button.backward,
.girl button.forward,
.girl button.submit {
  border: none;
  color: #fff;
  text-decoration: none;
  transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-align: center;
  background: #93178d;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  line-height: 1;
  padding: 12px 30px;
}



.girl.container_check input:checked ~ .girl.checkmark {
  background-color: #93178d;
  border: 1px solid transparent;
}

.girl.ui-widget-header {
  background: #93178d;
}
.girl.container_radio .girl.checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #93178d;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.girl.container_radio.version_2.active,
.girl.container_radio.version_2:hover,
.girl.container_check.version_2.active,
.girl.container_check.version_2:hover {
  border: 1px solid #93178d;
}


.girl.successChecked h1 {
  color: #b04ba8;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.girl.successChecked i {
  color: #b04ba8;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}


.girl.dial {
  background: #93178d;
  color: white;
}


.girl.autocompletion {
  font-size: 12px;
  margin-top: -10px !important;
  color: #93178d
}

.fig {
  background: #fff;
  padding: 1em;
}
.pied {
  display: flex;
  position: relative;
  height: 60px;
  width: 100%;
  background: #179349;
  justify-content: center;
  z-index: 3;
  bottom: 0;
  position: fixed;
}

.pied p {
  color: #fff;
  padding-top: 15px;
  margin-right: 70px;
}
ul.react-multi-carousel-track li{
  text-align: center;
}

ul.react-multi-carousel-track li img {
  height: 100px;
  width: 60%;
}

.column-wrap:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  background: rgb(245 246 248);
  padding: 5px 10px;
}

.r-135wba7 {
  line-height: 24px;
  height: 50px;
  display: flex;
  align-items: center;
}

.text-editor p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
}

.heading-title {
  color: #ff6b35;
}
.widget:not(:last-child) {
  margin-bottom: 20px;
}

.element, .widget-image {
  text-align: center;
}

svg.quote {
  font-size: 1em;
  color: #3DBB69;
}

svg.rotate {
  transform: rotate(180deg);
}

svg.rotate1 {
  transform: rotateX(180deg);
}

ol li {
  counter-increment: my-awesome-counter;
  padding-left: 36px;
  position: relative;
  margin-bottom: 0.5em;
}

ol li:before {
  content: counter(my-awesome-counter);
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  text-align: center;
  left: 0;
  top: 2px;
  width: 26px;
  line-height: 24px;
  height: 26px;
  border: 1px solid #179349;
  border-radius: 50%;
}

.slider-controls {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  width: 100%;
}

.row .slider-controls {
  margin-bottom: 30px;
} 

.slider .progress {
  display: block;
  height: 3px;
  margin: 0 10px;
  overflow: hidden;
  width: 120px;
  background-color: #e1e1e1;
  background-image: linear-gradient(to right, #179349, #179349);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.4s ease-in-out;
}

.slick-arrow {
  background: transparent;
  cursor: pointer;
  font-size: 0;
  z-index: 5;
  padding-top: 0;
  padding-bottom: 0;
}

.slick-arrow.slick-prev {
  background-image: url(https://lh3.googleusercontent.com/d/1fTxjkMugULRXZiIc-jUAC31IR94SPN2L);
  background-repeat: no-repeat;
  border: none;
  height: 25px;
  transition: 0.1s;
  width: 25px;
}

.slick-arrow.slick-next {
  background-image: url(https://lh3.googleusercontent.com/d/1WvP4kMMb7vCDfKPHLcxBIE8L9BK40cT_);
  background-repeat: no-repeat;
  border: none;
  height: 25px;
  transition: 0.1s;
  width: 25px;
}

.slick-initialized .slick-slide {
  display: block;
}



.parteners .sliderItem img {
  max-width: 150px;
  max-height: 150px;
}

.parteners .sliderItem img {
  --filter: grayscale(100%);
}

.parteners .sliderItem img:hover {
  filter: grayscale(0%);
}

.h-200{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.orange-shadow {
  box-shadow: inset 0 0 .5em#FF6C2C, 0 0 2em rgb(0 0 0 / 24%);
}

.green-shadow {
  box-shadow: inset 0 0 .5em #179349, 0 0 2em rgb(0 0 0 / 24%);
}

.social-shadow {
  box-shadow: rgb(0 0 0 / 24%) 0px 7px 10px
}

.parteners .slick-slider {
  border-radius: 5px;
}


@keyframes part-left {
  0% {
    opacity: 0;
    transform: translateX(10%); 
  }
  40%{
    opacity: 1;
    transform: translateX(-5%); 
  }

  70%{
    opacity: 1;
    transform: translateX(5%); 
  }

  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

.parteners .slick-slider.prev .row {
  animation: part-left .6s;
}

.parteners .slick-slider.next .row {
  animation: part-right .6s linear;
}
@keyframes part-right {
  0% {
    opacity: 0;
    transform: translateX(-10%); 
  }
  40%{
    opacity: 1;
    transform: translateX(5%); 
  }

  70%{
    opacity: 1;
    transform: translateX(-5%); 
  }
  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

.content-slider {
  position: relative;
}

.slick-list {
  margin: 0 -10px 20px -10px;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slides-3 .slider-content, .slides-2 .slider-content {
  --padding-top: 30px;
  text-align: center;
  margin-bottom: 100px;
}

.slider-content p:last-of-type {
  margin-bottom: 0;
}

.slick-dots {
  align-items: center;
  display: flex;
  margin: 0 15px;
  padding: 0;
}

.slides-3~div.slider-controls, .slides-2~div.slider-controls {
  --position: absolute;
  display: none;
}

.slick-dots li {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.slick-dots li.slick-active button {
  background: #179349;
  border: 2px solid #179349;
  cursor: default;
}

/* .slick-cloned img {
  align-items: center;
  display: block;
  max-width: 100%;
  height: 260px;
} */

@media screen and (min-width: 768px){
  .slick-dots li button {
    background: transparent;
}
}
.slick-dots li button {
    border: 2px solid gray;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    font-size: 0;
    height: 12px;
    padding: 0;
    width: 12px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 27px;
}
* {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.flip-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
}

@media screen and (min-width: 768px)
{
  .flipCardItem {
    width: calc(25% - 10px);
  }
  .flip-card-wrapper {
    margin: 0 -5px 20px -5px;
  }

  .counter span h3 {
    font-size: 4em;
  }
  
}

@media screen and (max-width: 479px) {
  .flipCardItem {
    margin-bottom: 20px;
    width: 100%!important;
  }

  .txt-wrap img, .flipCardItem-front img {
    width: 100%;
  }

  .counter span h3 {
    font-size: 2.5em;
  }
}

.flipCardItem {
  height: 350px;
  --margin: 5px;
}


.flipCardItem .flipCardItem-inner {
  cursor: pointer;
  height: 100%;
  padding: 5px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  width: 100%;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front {
  color: black;
  border-radius: 10px !important;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front, .flipCardItem .flipCardItem-inner .flipCardItem-back {
  box-shadow: rgb(0 0 0 / 24%) 0px 7px 10px;
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  backface-visibility: hidden;
}

.flipCardItem .flipCardItem-inner .flipCardItem-back {
  background-color: #179349;
  padding: 15px;
  transform: rotateY(180deg);
  padding : 15px;
}
.flipCardItem .flipCardItem-inner .flipCardItem-front, .flipCardItem .flipCardItem-inner .flipCardItem-back {
  box-shadow: rgb(0 0 0 / 24%) 0px 7px 10px;
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  backface-visibility: hidden;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front img {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  height: 60%;
  --border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front h2 {
  line-height: 1;
  margin-bottom: 10px;
  padding: 0 10px;
  font-weight: 700;
}

.flipCardItem .flipCardItem-inner p {
  font-size: 16px;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front span {
  bottom: 0;
  display: block;
  position: absolute;
  right: 0;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front span:before {
  background-image: url(https://lh3.googleusercontent.com/d/1uLUc-dTDCiku8ipsVEkH5SsguIKpl8vG);
  background-repeat: no-repeat;
  bottom: 5px;
  content: '';
  display: block;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  right: 5px;
  width: 30px;
}
.flipCardItem .flipCardItem-inner .flipCardItem-front span:before, .flipCardItem .flipCardItem-inner .flipCardItem-back span:before {
  backface-visibility: hidden;
}

.flipCardItem .flipCardItem-inner .flipCardItem-front span:after {
  border-top: 75px solid transparent;
  border-right: 75px solid #179349;
  content: "";
  display: block;
  height: 0;
  width: 0;
}

.flipCardItem .flipCardItem-inner .flipCardItem-back p {
  color: #f5f5f5;
  line-height: 1.2;
  margin-bottom: 0;
  text-align: left;
}

.flipCardItem .flipCardItem-inner p {
  padding: 0px 10px;
  font-size: 16px;
}

.flipCardItem .flipCardItem-inner .flipCardItem-back span {
  bottom: 0;
  position: absolute;
  right: 0;
}

.flipCardItem .flipCardItem-inner .flipCardItem-back span:before {
  background-image: url(https://lh3.googleusercontent.com/d/14m83MKRBxZwPpxaFzHi4Y-0urqcGIglA);
  background-repeat: no-repeat;
  bottom: 5px;
  content: '';
  display: block;
  height: 30px;
  position: absolute;
  transform: rotate(45deg);
  right: 5px;
  width: 30px;
}
.flipCardItem .flipCardItem-inner .flipCardItem-front span:before, .flipCardItem .flipCardItem-inner .flipCardItem-back span:before {
  backface-visibility: hidden;
}

.flipCardItem .flipCardItem-inner .flipCardItem-back span:after {
  border-top: 75px solid transparent;
  border-right: 75px solid white;
  content: "";
  display: block;
  height: 0;
  width: 0;
}

.flipCardItem.flipped .flipCardItem-inner {
  transform: rotateY(180deg);
}

p.blockquote__content {
  font-size: 16px;
  margin-bottom: 20px;
}

p.blockquote__content em, .text-wrap strong {
  font-weight: bold;
  
}

p.blockquote__content em {
  color: rgba(0, 0, 0, 0.821);
}

p.learn, p.blockquote__content, p.says {
  text-align: justify;
}

p.learn {
  padding: 0px 15px;
  min-height: 230px;
}

.models .sliderItem {
  padding: 10px 10px !important;
}

.content-model {
  box-shadow: rgb(0 0 0 / 24%) 0px 7px 10px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.content-model:hover{
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.content-model .title {
  padding: 20px;
}

.content-model .title p {
  font-weight: 700;
  font-size: 24px;
}

img.learn {
  border-top-right-radius: 20px;
  width: 100%;
}

iframe.tweet {
  border-radius: 10px !important;
}

.slide-right {
  animation: 3s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.slide-left {
  animation: 3s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

.counter span {
  margin-right: 10px !important;
}

.counter span h3{
  
  margin: 0px !important;
}

.counter span p {
  font-size: 1em;
}

.counter span h3::before {
  content: " : ";
}
.counter span.first h3::before{
  content:"" !important
}

.counter span.first.ff {
  margin-right: 10px !important;
}

.counter span.first.time h3 {
  font-size: 3em;
}
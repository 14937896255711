html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f5f6f8;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  color: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
}

p {
  margin-bottom: 25px;
}

strong {
  font-weight: 500;
}

label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #222;
}

hr {
  margin: 30px 0 30px 0;
  border-color: #ddd;
}

ul, ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

/*General links color*/
a {
  color: #179349;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
a:hover, a:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

a.animated_link {
  position: relative;
  text-decoration: none;
}

a.animated_link {
  position: relative;
  text-decoration: none;
}
a.animated_link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -5px;
  opacity: 1;
  left: 0;
  background-color: #179349;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a.animated_link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

a.animated_link.active {
  position: relative;
  text-decoration: none;
  color: #179349;
}
a.animated_link.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -5px;
  opacity: 1;
  left: 0;
  background-color: #179349;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.form-control {
  border: 1px solid #ddd !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  height: calc(2.65rem + 2px);
}
.form-control:focus {
  box-shadow: none !important;
  border-color: #179349 !important;
}

.add_bottom_10 {
  margin-bottom: 10px;
}

.add_bottom_15 {
  margin-bottom: 15px;
}

.add_bottom_20 {
  margin-bottom: 15px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  margin-bottom: 45px;
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_10 {
  margin-top: 10px;
}

.add_top_15 {
  margin-top: 15px;
}

.add_top_20 {
  margin-top: 20px;
}

.add_top_30 {
  margin-top: 30px;
}

.add_top_60 {
  margin-top: 60px;
}

.more_padding_left {
  padding-left: 40px;
}

.nomargin_top {
  margin-top: 0;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.margin_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}

.container {
  max-width: 1140px !important
}

.fl-form .fl-wrap {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl-form input.fl-input,
.fl-form select.fl-select,
.fl-form textarea.fl-textarea {
  width: 100%;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid none;
  background-color: white;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}
.fl-form input.fl-input:-moz-placeholder,
.fl-form select.fl-select:-moz-placeholder,
.fl-form textarea.fl-textarea:-moz-placeholder {
  color: #555;
  font-weight: 400;
}
.fl-form input.fl-input::-moz-placeholder,
.fl-form select.fl-select::-moz-placeholder,
.fl-form textarea.fl-textarea::-moz-placeholder {
  color: #555;
  font-weight: 400;
}
.fl-form input.fl-input:-ms-input-placeholder,
.fl-form select.fl-select:-ms-input-placeholder,
.fl-form textarea.fl-textarea:-ms-input-placeholder {
  color: #555;
  font-weight: 400;
  color: #FFF !important;
}
.fl-form input.fl-input::-webkit-input-placeholder,
.fl-form select.fl-select::-webkit-input-placeholder,
.fl-form textarea.fl-textarea::-webkit-input-placeholder {
  color: #555;
  font-weight: 400;
}

.fl-form select.fl-select {
  position: relative;
  color: #555;
  cursor: pointer;
}
.fl-form select.fl-select::-ms-expand {
  display: none;
}

.fl-form .fl-is-active input.fl-input,
.fl-form .fl-is-active select.fl-select,
.fl-form .fl-is-active textarea.fl-textarea {
  color: #222;
  background-color: white;
  border-color: none;
}

.fl-form .fl-has-focus input.fl-input,
.fl-form .fl-has-focus select.fl-select,
.fl-form .fl-has-focus textarea.fl-textarea {
  background-color: white;
  border-color: none;
}

.fl-form label.fl-label {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  border-top-left-radius: 3px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

body[dir=rtl] .fl-form label.fl-label {
  left: unset;
  right: 0;
}

.fl-form .fl-is-active label.fl-label {
  opacity: 1;
  visibility: visible;
}

.fl-form .fl-has-focus label.fl-label {
  color: #555;
}

.fl-form .fl-is-required:before {
  opacity: 1;
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  right: 18px;
  font-size: 14px;
  line-height: 1.75;
  color: #555;
  transition: all 0.3s ease-in-out;
  padding: 8px 0 0;
  z-index: 1;
}

body[dir=rtl] .fl-form .fl-is-required:before {
  right: unset;
  left: 18px;
}

.fl-form .fl-is-required.fl-is-active:before {
  opacity: 0;
}

.fl-form.fl-style-1 input.fl-input,
.fl-form.fl-style-1 select.fl-select,
.fl-form.fl-style-1 textarea.fl-textarea {
  padding: 8px 16px;
}

.fl-form.fl-style-1 select.fl-select {
  height: 46px;
}

.fl-form.fl-style-1 .fl-is-active input.fl-input,
.fl-form.fl-style-1 .fl-is-active select.fl-select,
.fl-form.fl-style-1 .fl-is-active textarea.fl-textarea {
  padding: 8px 16px;
}

.fl-form.fl-style-1 label.fl-label {
  top: 1px;
  left: 13px;
  background-color: transparent;
  padding: 16px 4px;
}

.fl-form.fl-style-1 label.fl-label:before {
  content: '';
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: white;
  z-index: -1;
}

.fl-form.fl-style-1 .fl-is-active label.fl-label {
  top: -10px;
  padding: 4px;
}

.fl-form.fl-style-1 .fl-is-active label.fl-label:before {
  top: 10px;
}

body[dir=rtl] .fl-form.fl-style-1 label.fl-label {
  left: unset;
  right: 13px;
}

.fl-form.fl-style-2 input.fl-input,
.fl-form.fl-style-2 select.fl-select,
.fl-form.fl-style-2 textarea.fl-textarea {
  padding: 16px;
}

.fl-form.fl-style-2 select.fl-select {
  height: 62px;
}

.fl-form.fl-style-2 .fl-is-active input.fl-input,
.fl-form.fl-style-2 .fl-is-active select.fl-select,
.fl-form.fl-style-2 .fl-is-active textarea.fl-textarea {
  padding: 24px 16px 8px;
}

.fl-form.fl-style-2 label.fl-label {
  top: 1px;
  left: 9.8px;
  padding: 8px 4px 4px;
}

.fl-form.fl-style-2 .fl-is-required:before {
  padding-top: 16px;
}

body[dir=rtl] .fl-form.fl-style-2 label.fl-label {
  left: unset;
  right: 13px;
}

.fl-form .fl-wrap-select:after {
  content: '';
  position: absolute;
  display: block;
  top: 1px;
  right: 6px;
  height: calc(100% - 2px);
  width: 14px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 16'%3E%3Cpath fill='%23555' d='M 4 0 L 0 6.5 L 8 6.5 L 4 0 z M 0 9.5 L 4 16 L 4 16 L 8 9.5 z'/%3E%3C/svg%3E") no-repeat;
  background-position: 100% 50%;
  background-size: 7px 14px;
  z-index: 2;
}

body[dir=rtl] .fl-form .fl-wrap-select:after {
  right: unset;
  left: 6px;
}

.toast {
  position: absolute;
  top: 25px;
  right: 30px;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-left: 6px solid #179349;;
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.toast.active {
  transform: translateX(0%);
  display: block;
}

.toast .toast-content {
  display: flex;
  align-items: center;
}

.toast-content svg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: #179349;;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.toast-content .message {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.message .text {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
}

.message .text.text-1 {
  font-weight: 600;
  color: #333;
}

.toast .close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.toast .close:hover {
  opacity: 1;
}

.toast .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #ddd;
}

.toast .progress:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #179349;;
}

.progress.active:before {
  animation: progress 5s linear forwards;
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
